@mixin theme($theme) {
    .mat-sort-header.arc-header-start > .mat-sort-header-container {
        justify-content: start;
    }
    .mat-sort-header.arc-header-center > .mat-sort-header-container {
        justify-content: center;
    }
    .mat-sort-header.arc-header-end > .mat-sort-header-container {
        justify-content: end;
    }

    .mat-sort-header > .mat-sort-header-container > .mat-sort-header-content {
        text-align: left !important;
    }
}
