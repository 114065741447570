/* Brand colors */
$black: #172A3A;
$white: #FFFFFF;
$petrol: #004346;
$green: #09BC8A;
$light-green: #C3E7DD;
$off-white: #F1F5F6;
$light-gray: #D9E2E7;
$gray: #B7C4CA;
$medium-gray: #94A1AB;
$dark-gray: #5C6770;
$dark: #354451;

$red: #EF6F6C;
$light-red: #FFD5D4;
$yellow: #E2AA3C;
$light-yellow: #FFF5D3;
$brown: #DDAE7E;
$light-brown: #EFDCC6;
$blue: #84ACCE;
$light-blue: #DEE8F1;
