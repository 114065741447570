@use '@angular/material' as mat;
@use 'sass:map';
@use 'material-palettes' as palettes;
@use 'material-typography' as typography;
@use 'custom-material-mixins/button.scss';
@use 'custom-material-mixins/select.scss';
@use 'custom-material-mixins/paginator.scss';
@use 'custom-material-mixins/tooltip.scss';
@use 'custom-material-mixins/drawer.scss';
@use 'custom-material-mixins/expansion-panel.scss';
@use 'custom-material-mixins/dialog.scss';
@use 'custom-material-mixins/card.scss';
@use 'custom-material-mixins/menu.scss';
@use 'custom-material-mixins/tab.scss';
@use 'custom-material-mixins/button-toggle.scss';
@use 'custom-material-mixins/form-field.scss';
@use 'custom-material-mixins/checkbox.scss';
@use 'custom-material-mixins/sort-header.scss';
@use 'custom-material-mixins/slider.scss';

@include mat.core();

$light-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: mat.m2-define-palette(palettes.$primary-light-palette, 500, 500, 500),
            accent: mat.m2-define-palette(palettes.$accent-light-palette, 500, 500, 500),
            warn: mat.m2-define-palette(palettes.$warn-light-palette, 500, 500, 500)
        ),
        // Only include 'typography' and 'density' in the default light theme.
        typography: typography.$typography,
        density: -2
    )
);

$dark-theme: mat.m2-define-dark-theme(
    (
        color: (
            primary: mat.m2-define-palette(palettes.$primary-dark-palette, 500, 500, 500),
            accent: mat.m2-define-palette(palettes.$accent-dark-palette, 500, 500, 500),
            warn: mat.m2-define-palette(palettes.$warn-dark-palette, 500, 500, 500)
        )
    )
);

// override light theme background and foreground colors
$light-color-palette: map.get($light-theme, 'color');
$light-color-palette: map.merge($light-color-palette, 'foreground', palettes.$foreground-light-palette);
$light-color-palette: map.merge($light-color-palette, 'background', palettes.$background-light-palette);
$light-theme: map.set($light-theme, 'color', $light-color-palette);

// override dark theme background and foreground colors
$dark-color-palette: map.get($dark-theme, 'color');
$dark-color-palette: map.merge($dark-color-palette, 'foreground', palettes.$foreground-dark-palette);
$dark-color-palette: map.merge($dark-color-palette, 'background', palettes.$background-dark-palette);
$dark-theme: map.set($dark-theme, 'color', $dark-color-palette);

// apply light theme by default
@include mat.core-theme($light-theme);
@include mat.all-component-themes($light-theme);
@include mat.button-density(0);
@include mat.expansion-density(0);

// apply custom component mixins
@include button.theme($light-theme);
@include select.theme($light-theme);
@include paginator.theme($light-theme);
@include tooltip.theme($light-theme);
@include drawer.theme($light-theme);
@include expansion-panel.theme($light-theme);
@include dialog.theme($light-theme);
@include card.theme($light-theme);
@include menu.theme($light-theme);
@include tab.theme($light-theme);
@include button-toggle.theme($light-theme);
@include form-field.theme($light-theme);
@include checkbox.theme($light-theme);
@include sort-header.theme($light-theme);
@include slider.theme($light-theme);

// apply dark theme when activated
:root:has(arc-root.dark) {
    @include mat.core-color($dark-theme);
    @include mat.all-component-colors($dark-theme);

    // apply custom component mixins
    @include button.theme($dark-theme);
    @include select.theme($dark-theme);
    @include paginator.theme($dark-theme);
    @include tooltip.theme($dark-theme);
    @include drawer.theme($dark-theme);
    @include expansion-panel.theme($dark-theme);
    @include dialog.theme($dark-theme);
    @include card.theme($dark-theme);
    @include menu.theme($dark-theme);
    @include tab.theme($dark-theme);
    @include button-toggle.theme($dark-theme);
    @include form-field.theme($dark-theme);
    @include checkbox.theme($dark-theme);
    @include sort-header.theme($dark-theme);
    @include slider.theme($dark-theme);
}
