@use '@angular/material' as mat;
@use '../colors.scss';

@mixin style() {
    mat-tab-group .mat-mdc-tab-body-wrapper {
        flex-grow: 1;
    
        .mat-mdc-tab-body-content {
            display: flex;
            flex-direction: column;
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include style();
    }
}
