@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    /* no box shadow */
    mat-card.mat-mdc-card {
        box-shadow: none;
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
