@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    mat-select {
        --mat-select-enabled-trigger-text-color: #{colors.get-theme-variable('on-app')};
        --mat-select-enabled-arrow-color: #{colors.get-theme-variable('on-app-light')};
    }

    mat-select:hover .mat-mdc-select-arrow * {
        color: colors.get-theme-variable('accent') !important;
    }

    mat-option:hover {
        background: colors.get-theme-variable('element-hover') !important;
    }

    mat-option.mat-mdc-option-active {
        background: colors.get-theme-variable('element-selected') !important;
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
