@use './colors.scss';
@use './brand-colors.scss' as brand;
@use './mde-styles.scss';

@import 'quill/dist/quill.snow.css';
@import './quill.custom.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-style: unset;
}

html,
body {
    height: 100%;
    font-size: 14px;
}

body {
    margin: 0;
    font-family: Poppins, 'Helvetica Neue', sans-serif;
}

.material-icons {
    font-family: 'Material Icons Round';
}

/* Custom css classes */

.arc-form-field {
    width: 100%;
}

/* display material icons inside matBadge */
.arc-mat-badge-icon > .mat-badge-content {
    font-family: 'Material Icons';
}

.arc-dynamic-table-cell {
    /* proper display of material controls in dynamic table */
    mat-form-field {
        height: 100%;

        .mat-mdc-form-field-flex {
            align-items: center;

            .mat-mdc-form-field-infix {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                min-height: 0 !important;
                width: 100%;
            }
        }
    }
}

/* remove arrows in number input*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

/* global styling for markdown elements */

.arc-markdown {
    @apply prose     
    prose-indigo     
    prose-h2:text-3xl    
    prose-h2:font-medium
    prose-h2:text-on-app     
    prose-h2:leading-5   
    prose-h2:mt-4
    prose-h2:mb-8 
    prose-h3:text-2xl 
    prose-h3:font-medium
    prose-h3:text-on-app     
    prose-h3:leading-5
    prose-h3:mt-3       
    prose-h3:mb-6       
    prose-h4:text-base
    prose-h4:font-medium
    prose-h4:text-on-app
    prose-h4:mt-3       
    prose-h4:mb-6
    prose-p:text-base
    prose-p:mt-2
    prose-p:mb-4;

    .image {
        img,
        svg {
            @apply w-auto h-auto max-w-full m-0;
        }
    }

    max-width: 100%;
    width: 100%;

    .green {
        color: var(--accent);
    }

    > *:first-child,
    section > *:first-child {
        margin-top: 0;
    }
    > *:last-child,
    section > *:last-child {
        margin-bottom: 0;
    }

    section {
        padding: 1.5rem 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
}
.mat-expansion-panel .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused,
.mat-expansion-panel .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused {
    background: inherit !important;
}

.mat-mdc-radio-button .mdc-radio--disabled+label {
    color: colors.get-theme-variable('element-disabled') !important;
}

:root {
    scrollbar-color: brand.$dark-gray brand.$off-white;
    &:has(arc-root.dark) {
        scrollbar-color: brand.$black brand.$dark;
    }
}
