@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    mat-tooltip-component .mat-mdc-tooltip {
        --mdc-plain-tooltip-container-color: #{colors.get-theme-variable('element-selected')};
        --mdc-plain-tooltip-supporting-text-color: #{colors.get-theme-variable('on-app')};

        &.arc-tooltip-warn {
            --mdc-plain-tooltip-container-color: #{colors.get-theme-variable('error-light')};
            --mdc-plain-tooltip-supporting-text-color: #{colors.get-theme-variable('on-error-light')};
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
