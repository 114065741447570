@use 'themes/light';
@use 'themes/dark';

/* Primary */
$primary-light-palette: (
    500: light.$primary,
    contrast: (
        500: light.$on-primary,
    )
);

$primary-dark-palette: (
    500: dark.$primary,
    contrast: (
        500: dark.$on-primary,
    )
);

/* Accent */
$accent-light-palette: (
    500: light.$accent,
    contrast: (
        500: light.$on-accent,
    )
);

$accent-dark-palette: (
    500: dark.$accent,
    contrast: (
        500: dark.$on-accent,
    )
);

/* Warn */
$warn-light-palette: (
    500: light.$error,
    contrast: (
        500: light.$on-error,
    )
);

$warn-dark-palette: (
    500: dark.$error,
    contrast: (
        500: dark.$on-error,
    )
);

/* Foreground */
$foreground-light-palette: (
    base:               light.$on-app,
    icon:               light.$on-app,
    icons:              light.$on-app

    /* defaults material v18 */
    // base: black,
    // divider: rgba(0, 0, 0, 0.12),
    // dividers: rgba(0, 0, 0, 0.12),
    // disabled: rgba(0, 0, 0, 0.38),
    // disabled-button: rgba(0, 0, 0, 0.26),
    // disabled-text: rgba(0, 0, 0, 0.38),
    // elevation: black,
    // hint-text: rgba(0, 0, 0, 0.38),
    // secondary-text: rgba(0, 0, 0, 0.54),
    // icon: rgba(0, 0, 0, 0.54),
    // icons: rgba(0, 0, 0, 0.54),
    // text: rgba(0, 0, 0, 0.87),
    // slider-min: rgba(0, 0, 0, 0.87),
    // slider-off: rgba(0, 0, 0, 0.26),
    // slider-off-active: rgba(0, 0, 0, 0.38)
);

$foreground-dark-palette: (
    base:               dark.$on-app,
    icon:               dark.$on-app,
    icons:              dark.$on-app

    /* defaults material v18 */
    // base: #172A3A,
    // divider: rgba(0, 0, 0, 0.12),
    // dividers: rgba(0, 0, 0, 0.12),
    // disabled: rgba(0, 0, 0, 0.38),
    // disabled-button: rgba(0, 0, 0, 0.26),
    // disabled-text: rgba(0, 0, 0, 0.38),
    // elevation: black,
    // hint-text: rgba(0, 0, 0, 0.38),
    // secondary-text: rgba(0, 0, 0, 0.54),
    // icon: #172A3A,
    // icons: #172A3A,
    // text: rgba(0, 0, 0, 0.87),
    // slider-min: rgba(0, 0, 0, 0.87),
    // slider-off: rgba(0, 0, 0, 0.26),
    // slider-off-active: rgba(0, 0, 0, 0.38)
);

/* Background */
$background-light-palette: (
    status-bar:                 light.$element-selected,
    app-bar:                    light.$top-bar,
    background:                 light.$app,
    card:                       light.$element,
    dialog:                     light.$element,
    disabled-button:            light.$element-disabled,
    raised-button:              light.$element,
    focused-button:             light.$element-hover,
    selected-button:            light.$element-selected,
    selected-disabled-button:   light.$element-disabled,
    disabled-button-toggle:     light.$element-disabled,
    disabled-list-option:       light.$element-disabled,

    /* defaults material v18 */
    // status-bar: #e0e0e0,
    // app-bar: #f5f5f5,
    // background: #fafafa,
    // hover: rgba(0, 0, 0, 0.04),
    // card: white,
    // dialog: white,
    // disabled-button: rgba(0, 0, 0, 0.12),
    // raised-button: white,
    // focused-button: rgba(0, 0, 0, 0.12),
    // selected-button: #e0e0e0,
    // selected-disabled-button: #bdbdbd,
    // disabled-button-toggle: #eeeeee,
    // unselected-chip: #e0e0e0,
    // disabled-list-option: #eeeeee,
    // tooltip: #616161
);

$background-dark-palette: (
    status-bar:                 dark.$element-selected,
    app-bar:                    dark.$top-bar,
    background:                 dark.$app,
    card:                       dark.$element,
    dialog:                     dark.$element,
    disabled-button:            dark.$element-disabled,
    raised-button:              dark.$element,
    focused-button:             dark.$element-hover,
    selected-button:            dark.$element-selected,
    selected-disabled-button:   dark.$element-disabled,
    disabled-button-toggle:     dark.$element-disabled,
    disabled-list-option:       dark.$element-disabled

    /* defaults material v18 */
    // status-bar: #D9E2E7,
    // app-bar: #172A3A,
    // background: #F1F5F6,
    // hover: rgba(0, 0, 0, 0.04),
    // card: #FFFFFF,
    // dialog: #FFFFFF,
    // disabled-button: #B7C4CA,
    // raised-button: #FFFFFF,
    // focused-button: #F1F5F6,
    // selected-button: #D9E2E7,
    // selected-disabled-button: #B7C4CA,
    // disabled-button-toggle: #B7C4CA,
    // unselected-chip: #e0e0e0,
    // disabled-list-option: #B7C4CA,
    // tooltip: #616161
);
