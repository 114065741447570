@use 'colors.scss';

/* Markdown editor styles */

.mde-small {
    .EasyMDEContainer .CodeMirror {
        height: 120px;
    }
}

.arc-mde-editor-error .EasyMDEContainer {
    border: 1px solid colors.get-theme-variable('error') !important;
}