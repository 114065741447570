@use '@angular/material' as mat;
@use '../colors.scss';

@mixin styles($theme) {
    /* use flexbox for dialogs */
    .mat-mdc-dialog-container > .mdc-dialog__container > .mat-mdc-dialog-surface {
        display: flex;
        flex-direction: column;
    }

    /* use our color for content */
    .mat-mdc-dialog-container .mdc-dialog__content {
        --mdc-dialog-supporting-text-color: #{colors.get-theme-variable('on-app')};
    }

    /* custom box shadow */
    .mat-mdc-dialog-surface {
        --mat-dialog-container-elevation-shadow: #{colors.get-theme-variable('box-shadow')};
    }

    /* action buttons should always be on the right */
    .mat-mdc-dialog-actions:not(.arc-dialog-actions-start-left) {
        justify-content: end !important;
    }

    // margin for loading buttons
    .mat-mdc-dialog-actions {
        arc-button {
            margin-left: 8px;
        }
    }

    .cdk-overlay-backdrop-showing {
        background-color: rgba(0, 0, 0, 0.2) !important;
        backdrop-filter: blur(4px);
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include styles($theme);
    }
}
