@use '@angular/material' as mat;
@use '../colors.scss';

@mixin styles($theme) {
    /* checkbox colors */
    .mdc-checkbox__background {
        --mdc-checkbox-disabled-unselected-icon-color: #{colors.get-theme-variable('element-disabled')};
        --mdc-checkbox-disabled-selected-icon-color: #{colors.get-theme-variable('element-disabled')};
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include styles($theme);
    }
}
