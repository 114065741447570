@use '../brand-colors' as brand;

/* General Colors */
$primary:               brand.$petrol;
$on-primary:            brand.$white;
$accent:                brand.$green;
$on-accent:             brand.$white;
$success:               brand.$green;
$on-success:            brand.$white;
$warn:                  brand.$yellow;
$on-warn:               brand.$white;
$error:                 brand.$red;
$on-error:              brand.$white;
$info:                  brand.$blue;
$on-info:               brand.$white;
$success-light:         brand.$light-green;
$on-success-light:      brand.$black;
$warn-light:            brand.$light-yellow;
$on-warn-light:         brand.$black;
$error-light:           brand.$light-red;
$on-error-light:        brand.$black;
$info-light:            brand.$light-blue;
$on-info-light:         brand.$black;
$brown-light:           brand.$light-brown;
$on-brown-light:        brand.$black;

$app:                   brand.$dark;
$on-app:                brand.$white;
$on-app-light:          brand.$off-white;
$element:               brand.$black;
$element-hover:         brand.$black;
$element-selected:      brand.$dark-gray;
$element-disabled:      brand.$gray;
$on-element-disabled:   brand.$white;
$icon-light:            brand.$medium-gray;
$icon-hover:            brand.$green;

/* Specific colors */
$top-bar:               brand.$black;
$on-top-bar:            brand.$gray;
$on-top-bar-hover:      brand.$white;
$on-top-bar-selected:   brand.$green;
$widget:                brand.$black;
$on-widget:             brand.$gray;
$power-search:          brand.$black;
$on-power-search:       brand.$white;
$fab-more:              brand.$dark-gray;
$on-fab-more:           brand.$white;
$overlay:               rgba(brand.$dark, 0.6);
$global-overlay:        rgba(brand.$black, 0.8);

/* Variables */
$box-shadow:            0 3px 20px rgba(0, 0, 0, 0.15);
$box-shadow-dashboard:  0 3px 5px rgba(0, 0, 0, .15);

/* Charts */
$chart-comparison:      brand.$dark-gray;
$chart-line:            brand.$green;
$chart-budget:          brand.$red;

/* Report Cards */
$available-reports:     brand.$dark-gray;
$card-image:            brand.$light-gray;

/* Borders */
$border-not-selected:   rgba(255, 255, 255, 0.38);
$border-hover:          rgba(255, 255, 255, 0.87);
$border-focus:          brand.$petrol;

/* Extension Cards */
$available-extensions:  brand.$dark-gray;