@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    mat-expansion-panel-header {
        @apply px-4 smd:px-2 #{!important};
    }

    .mat-expansion-panel-body {
        @apply p-4 smd:p-2 smd:pt-0 #{!important};
    }

    /* no box shadow */
    mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]) {
        box-shadow: none;
    }

    /* small border instead of box shadow */
    mat-accordion mat-expansion-panel {
        border: 2px solid colors.get-theme-variable('app');
        
        &:not(.mat-expanded):not(:first-of-type) {
            margin-top: -2px !important;
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
