@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    mat-slider {
        --mdc-slider-inactive-track-color: #{colors.get-theme-variable('element-selected')};
    }

    mat-slider.red {
        --mdc-slider-active-track-color: #{colors.get-theme-variable('error')};
    }

    mat-slider.green {
        --mdc-slider-active-track-color: #{colors.get-theme-variable('accent')};
    }

    mat-slider.yellow {
        --mdc-slider-active-track-color: #{colors.get-theme-variable('warn')};
        
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
