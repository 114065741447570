@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    // if the form field is larger than the content, the content should be centered vertically
    .mat-mdc-text-field-wrapper {
        align-items: center !important;
    }

    .mat-mdc-form-field-icon-suffix {
        margin-right: 0.5rem;
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
