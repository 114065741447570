@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    /* custom box shadow */
    .mdc-menu-surface {
        box-shadow: colors.get-theme-variable('box-shadow') !important;
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
