@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    /* fix material setting wrong text color for filled buttons */
    button {
        &[color=primary] {
            --mdc-filled-button-label-text-color: #{colors.get-theme-variable('on-primary') !important};
        }

        &[color=accent] {
            --mdc-filled-button-label-text-color: #{colors.get-theme-variable('on-accent') !important};
        }

        &[color=warn] {
            --mdc-filled-button-label-text-color: #{colors.get-theme-variable('on-error') !important};
        }

        &[color=warning] {
            --mdc-filled-button-label-text-color: #{colors.get-theme-variable('on-warn') !important};
            --mdc-filled-button-container-color: #{colors.get-theme-variable('warn') !important};
        }
    }

    /* icon button */
    button[mat-icon-button]:hover {
        color: colors.get-theme-variable('accent');
    }

    /* floating action button */
    button[mat-fab], button[mat-mini-fab] {
        box-shadow: colors.get-theme-variable('box-shadow');
    }

    button[mat-fab], button[mat-mini-fab] {
        &[color=primary] {
            color: colors.get-theme-variable('on-primary') !important;
        }

        &[color=accent] {
            color: colors.get-theme-variable('on-accent') !important;
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
