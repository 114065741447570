@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    /* custom box shadow */
    .mat-drawer:not(.mat-drawer-side) {
        box-shadow: colors.get-theme-variable('box-shadow');
    }

    /* custom backdrop color */
    .mat-drawer-backdrop {
        background-color: rgba(0, 0, 0, 0.2) !important;
        backdrop-filter: blur(4px);
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
